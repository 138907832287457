import React, { useState } from "react"
import { Link } from "gatsby"
import * as FaIcons from "react-icons/fa"

const MobileMenu = ({ showMenu }) => {
  const [showDropDown, setShowDropDown] = useState(false)
  return (
    <div id="mobile-menu">
      <div className="top">
        <FaIcons.FaTimes
          onClick={() => showMenu(false)}
          id="mobile-menu-icon"
        />
      </div>
      <div className="links">
        <ul>
          <li>
            <Link onClick={() => showMenu(false)} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={() => showMenu(false)} to="/challenge">
              The Challenge
            </Link>
          </li>
          <li>
            <div className="wrap">
              <Link onClick={() => showMenu(false)} to="/answer">
                The Answer
              </Link>
              <FaIcons.FaChevronDown
                id="mobile-chev-down"
                style={{
                  transform: showDropDown ? "rotate(-180deg)" : "rotate(0deg)",
                }}
                onClick={() => setShowDropDown(!showDropDown)}
              />
            </div>
            {showDropDown && (
              <div className="mobile-dropdown">
                <ul>
                  <li>
                    <Link
                      onClick={() => showMenu(false)}
                      to="/answer/equitable-access"
                    >
                      Equitable Access
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => showMenu(false)}
                      to="/answer/personalized-learning"
                    >
                      Personalized Learning
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => showMenu(false)}
                      to="/answer/job-life-skills"
                    >
                      Job & Life Skills
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li>
            <Link onClick={() => showMenu(false)} to="/student-voices">
              Student Voices
            </Link>
          </li>
          <li>
            <Link onClick={() => showMenu(false)} to="/news">
              News
            </Link>
          </li>
          <li>
            <Link onClick={() => showMenu(false)} to="/who-we-are">
              Who We Are
            </Link>
          </li>
          <li>
            <Link onClick={() => showMenu(false)} to="/join-us">
              Join Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MobileMenu
