import React, { useState } from "react"
import { Link } from "gatsby"
import * as FaIcons from "react-icons/fa"
import logo from "../../images/logo.png"
import circleLogo from "../../images/circle-logo.png"
import MobileMenu from "./mobileMenu"
import SearchBar from "../SearchBar"

const Header = () => {
  const [showDrop, setShowDrop] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  return (
    <>
      {showMobileMenu && <MobileMenu showMenu={setShowMobileMenu} />}
      <header id="header">
        <div className="top-bar">
          <div className="container">
            <Link to="/" id="header-long-logo">
              <img src={logo} alt="" />
            </Link>
            <div className="mobile-top-header">
              <Link to="/" id="header-circle-logo">
                <img src={circleLogo} alt="" />
              </Link>
              <FaIcons.FaBars
                onClick={() => setShowMobileMenu(true)}
                id="mobile-ham"
              />
            </div>
            <SearchBar />
          </div>
        </div>
        <div className="links">
          <div className="container">
            <ul>
              <li>
                <Link to="/" onClick={() => setShowDrop(false)}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/challenge" onClick={() => setShowDrop(false)}>
                  The Challenge
                </Link>
              </li>
              <div
                className="dropdown"
                onMouseOver={() => {
                  setShowDrop(true)
                }}
              >
                <li>
                  <Link
                    to="/answer"
                    className="dropbtn"
                    onClick={() => setShowDrop(false)}
                  >
                    <span>The Answer</span>{" "}
                    <FaIcons.FaChevronDown
                      className="chev-down"
                      style={{
                        transform: showDrop
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </Link>
                  {showDrop && (
                    <div
                      className="dropdown-content"
                      onMouseOver={() => setShowDrop(true)}
                      onMouseLeave={() => {
                        setShowDrop(false)
                      }}
                    >
                      <Link
                        to="/answer/equitable-access"
                        onClick={() => setShowDrop(false)}
                      >
                        Equitable Access
                      </Link>
                      <Link
                        to="/answer/personalized-learning"
                        onClick={() => setShowDrop(false)}
                      >
                        Personalized Learning
                      </Link>
                      <Link
                        to="/answer/job-life-skills"
                        onClick={() => setShowDrop(false)}
                      >
                        Job & Life Skills
                      </Link>
                    </div>
                  )}
                </li>
              </div>
              <li>
                <Link to="/student-voices" onClick={() => setShowDrop(false)}>
                  Student Voices
                </Link>
              </li>
              <li>
                <Link to="/news" onClick={() => setShowDrop(false)}>
                  News
                </Link>
              </li>
              <li>
                <Link to="/who-we-are" onClick={() => setShowDrop(false)}>
                  Who We Are
                </Link>
              </li>
              <li>
                <Link to="/join-us" onClick={() => setShowDrop(false)}>
                  Join Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
