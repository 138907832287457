import React, { useState } from "react"
import { Link } from "gatsby"
import * as FaIcons from "react-icons/fa"
import logo from "../../images/logo-2.png"
import EmailOnlyNewsLetter from "../EmailOnlyNewsLetter"
import MessageBanner from "../MessageBanner"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Footer = () => {
  const [showMessage, setShowMessage] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState(true)
  const [isContact, setIsContact] = useState(true)
  return (
    <>
      {showMessage && (
        <MessageBanner
          showBanner={setShowMessage}
          status={msgSuccess}
          type={isContact}
        />
      )}
      <footer id="footer">
        <div className="container">
          <div className="top">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
            <div className="search-bar">
              <p>Sign up for our newsletter</p>
              <EmailOnlyNewsLetter
                showMessage={setShowMessage}
                setStatus={setMsgSuccess}
                setType={setIsContact}
              />
            </div>
          </div>
          <div className="middle">
            <div className="links">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/challenge">The Challenge</Link>
                </li>
                <li>
                  <Link to="/answer">The Answer</Link>
                </li>
                <li>
                  <Link to="/student-voices">Student Voices</Link>
                </li>
                <li>
                  <Link to="/news">News</Link>
                </li>

                <li>
                  <Link to="/who-we-are">Who We Are</Link>
                </li>
                <li>
                  <Link to="/join-us">Join Us</Link>
                </li>
              </ul>
            </div>
            <div className="social">
              <OutboundLink
                target="_blank"
                href="https://www.facebook.com/gogood4u"
              >
                <FaIcons.FaFacebookF className="facebook icon" />
              </OutboundLink>
              <OutboundLink target="_blank" href="https://twitter.com/GoGood4U">
                <FaIcons.FaTwitter className="twitter icon" />
              </OutboundLink>
              <OutboundLink
                target="_blank"
                href="https://www.instagram.com/gogood4u/"
              >
                <FaIcons.FaInstagram className="instagram icon" />
              </OutboundLink>
              <OutboundLink
                target="_blank"
                href="https://www.youtube.com/channel/UCBynMBZ9qrE-VgPU6plA3bQ"
              >
                <FaIcons.FaYoutube className="youtube icon" />
              </OutboundLink>
            </div>
          </div>
          <div className="mobile-social">
            <OutboundLink
              target="_blank"
              href="https://www.facebook.com/gogood4u"
            >
              <FaIcons.FaFacebookF className="facebook icon" />
            </OutboundLink>
            <OutboundLink target="_blank" href="https://twitter.com/GoGood4U">
              <FaIcons.FaTwitter className="twitter icon" />
            </OutboundLink>
            <OutboundLink
              target="_blank"
              href="https://www.instagram.com/gogood4u/"
            >
              <FaIcons.FaInstagram className="instagram icon" />
            </OutboundLink>
            <OutboundLink
              target="_blank"
              href="https://www.youtube.com/channel/UCBynMBZ9qrE-VgPU6plA3bQ"
            >
              <FaIcons.FaYoutube className="youtube icon" />
            </OutboundLink>
          </div>
          <div className="bottom">
            <div className="links">
              <ul>
                <li>
                  <Link to="/accessibility">Website Accessibility</Link>
                </li>
                {/* <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li> */}
              </ul>
            </div>
            <div className="copyright">
              Copyright © {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
