import * as React from "react"
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./footer"

import "../../style/main.scss"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        {/* <!-- Begin Constant Contact Active Forms --> */}
        <script> var _ctct_m = "5f735044793f7d070c7cda4c9082a654"; </script>
        <script
          id="signupScript"
          src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js"
          async
          defer
        ></script>
        {/* <!-- End Constant Contact Active Forms --> */}
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
