import React, { useEffect } from "react"
import * as FaIcons from "react-icons/fa"

const MessageBanner = ({ showBanner, status, type }) => {
  useEffect(() => {
    let timer1 = setTimeout(() => showBanner(false), 5000)
    return () => {
      clearTimeout(timer1)
    }
  }, [])
  let msgText = status
    ? type
      ? "Message has been sent!"
      : "Success! You are signed up"
    : "Error: Message has failed"
  return (
    <div id="message-overlay" onClick={() => showBanner(false)}>
      <div id="message-banner" className="animate__pulse">
        <p style={{ color: status ? "#20657f" : "red" }}>{msgText}</p>
        <FaIcons.FaTimes id="banner-close-icon" />
      </div>
    </div>
  )
}

export default MessageBanner
