// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-answer-equitable-access-js": () => import("./../../../src/pages/answer/equitable-access.js" /* webpackChunkName: "component---src-pages-answer-equitable-access-js" */),
  "component---src-pages-answer-job-life-skills-js": () => import("./../../../src/pages/answer/job-life-skills.js" /* webpackChunkName: "component---src-pages-answer-job-life-skills-js" */),
  "component---src-pages-answer-js": () => import("./../../../src/pages/answer.js" /* webpackChunkName: "component---src-pages-answer-js" */),
  "component---src-pages-answer-personalized-learning-js": () => import("./../../../src/pages/answer/personalized-learning.js" /* webpackChunkName: "component---src-pages-answer-personalized-learning-js" */),
  "component---src-pages-challenge-js": () => import("./../../../src/pages/challenge.js" /* webpackChunkName: "component---src-pages-challenge-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-new-page-js": () => import("./../../../src/pages/new-page.js" /* webpackChunkName: "component---src-pages-new-page-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-student-voices-js": () => import("./../../../src/pages/student-voices.js" /* webpackChunkName: "component---src-pages-student-voices-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/articleList.js" /* webpackChunkName: "component---src-templates-article-list-js" */)
}

