import React, { useState } from "react"
import * as FaIcons from "react-icons/fa"
import { navigate } from "@reach/router"

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [placeholder, setPlaceholder] = useState("Search")

  const onSubmit = () => {
    if (searchTerm === "") {
      setPlaceholder("Please enter search text")
    } else {
      setSearchTerm("")
      navigate(`/search-results/`, { state: { query: searchTerm } })
    }
  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      onSubmit()
    }
  }

  return (
    <div className="search">
      <input
        type="search"
        name="search"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        placeholder={placeholder}
        onKeyPress={e => handleKeyPress(e)}
      />
      <div className="circle-btn" onClick={() => onSubmit()}>
        <FaIcons.FaSearch className="search-btn-icon" />
      </div>
    </div>
  )
}

export default SearchBar
