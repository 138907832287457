import React, { useState } from "react"
import axios from "axios"

const EmailOnlyNewsLetter = ({ showMessage, setStatus, setType }) => {
  const [formData, setFormData] = useState({
    email_address: "",
  })
  const { email_address } = formData
  const onChangeText = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const resetForm = () => {
    setFormData({
      name: "",
      email_address: "",
    })
  }
  const onSubmit = async e => {
    e.preventDefault()
    const data = {
      first_name: "",
      last_name: "",
      email_address,
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    try {
      const res = await axios.post(
        "https://llac-static-site-api.azurewebsites.net/api/constant/newsletter",
        data,
        config
      )
      console.log(res.status)
      setStatus(true)
      setType(false)
      resetForm()
      showMessage(true)
    } catch (err) {
      console.error({ err })
      setStatus(false)
      resetForm()
      showMessage(true)
    }
  }
  return (
    <form onSubmit={onSubmit} className="search">
      <input
        type="email"
        name="email_address"
        value={email_address}
        onChange={e => onChangeText(e)}
        placeholder="Email Address"
        required
      />
      <input type="submit" value="Send" className="send-btn" />
    </form>
  )
}

export default EmailOnlyNewsLetter
